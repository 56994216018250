@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;700&display=swap');

main {
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  background-color: white;
}

/* Page Sections and Columns */
.section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.section-padding {
  margin-left: auto;
  margin-right: auto;
  max-width: 90rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.section-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 6rem;
  margin-top: 2.5rem;
}

.section-grid__column {
  grid-column: span 1 / span 1;
}

.section-title {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  margin-left: auto;
  font-size: 3rem;
  line-height: 2.25rem;
  font-weight: 700;
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: -0.025rem;
  color: #111827; 
  text-align: left;
}

.section-title__underline {
  border-bottom-color: #7a4491;
  border-top-color: #7a4491;
  border-bottom-width: 3px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

.section-picture {
  margin-top: 1rem;
  border-radius: 2rem;
  border-width: 0.3rem;
  border-color: #1987c0;
  height: auto;
  width: 700px;
}

.col-title {
  font-size: 2rem;
  font-weight: 700;
  text-align: left;
  margin-top: 1rem;
}

.col-title__underline {
  border-bottom-color: #1987c0;
  border-top-color: #1987c0;
  border-bottom-width: 3px;
  border-radius: 5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.col-text {
  text-align: left;
  margin-top: 1rem;
  font-size: 1.3rem;
}

.col-text a {
  text-decoration: underline;
}

.col-text a:hover {
  color: #1987c0;
}

/* Features List */
.features-list__item-title {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  color: #111827;
}

.features-list__icon-box {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: #1987c0;
}

.features-list__icon-box:hover {
  background-color: #7a4491;
  box-shadow: 0 0 0.5rem 0.25rem rgba(192, 191, 192, 0.5);
  animation: wiggle 0.5s;
  animation-iteration-count: 1;
}

.features-list__icon-outline {
  height: 1.5rem;
  width: 1.5rem;
  color: white;
}

.features-text {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.75rem;
  color: black;
  margin-left: 1rem;
}

#vision {
  margin-left: 0;
}

#mission {
  margin-left: 0;
}

.features-text ul {
  list-style-type: disc;
}

#microreviews {
  background-color: #7a4491;
}

#microreviews:hover {
  background-color: #1987c0;
}

/* Accordions */
.accordion-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: rgba(192, 191, 192, 0.5);
  border-radius: 2rem;
  border-width: 0.3rem;
  border-color: #1987c0;
}

.accordion__divide {
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.25rem;
}

.accordion-header {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-weight: 700;
  font-size: 1.3rem;
  color: black;
  padding: 1rem;
}

.accordion-body {
  text-align: left;
  font-size: 1.2rem;
  color: black;
  margin-bottom: 1.25rem;
}

.accordion-list {
  list-style-type: disc;
  list-style-position: inside;
  text-align: left;
  color: black;
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;
}

.accordion-body ul {
  list-style-type: disc;
  list-style-position: inside;
  text-align: left;
  margin-left: 1.25rem;
}

.accordion-body img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  display: block;
}

.accordion-body h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.accordion-body__item {
  text-decoration-line: underline;
  font-weight: bold;
}

.accordion-body__item:hover {
  color: #1987c0;
}

/* Footer */
.main-footer {
  background-color: #1987c0;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  position: relative;
  width: 100%;
  bottom: 0;
}

.main-footer__picture {
  height: 5rem;
  margin-right: 0.75rem;
}

.main-footer hr {
  border-color: white;
}

.main-footer a:hover {
  color: #7a4491;
}

.main-footer svg {
  fill: white;
  margin-top: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

#linkedin {
  width: 1.25rem;
  height: 1.25rem;
}

.main-footer svg:hover {
  fill: #7a4491;
}

.main-footer span {
  font-size: 1.1rem;
}

@media (max-width: 820px) {
  .section-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
  }

  .section-grid__column {
    grid-column: span 2 / span 2;
  }

  .section-picture {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .section-grid__column {
    grid-column: span 2 / span 2;
  }

  .features-list__item-title {
    font-size: 2.2rem;
  }

  .features-text {
    font-size: 1.8rem;
    line-height: 2.25rem;
  }

  .features-list__icon-box {
    height: 3rem;
    width: 3rem;
  }

  .features-list__icon-outline {
    height: 2rem;
    width: 2rem;
  }

  .section-title {
    font-size: 3.6rem;
  }

  .col-title {
    font-size: 2.6rem;
  }

  .col-text {
    font-size: 2rem;
  }

  .accordion-header {
    font-size: 1.9rem;
  }

  .accordion-body {
    font-size: 1.7rem;
  }

  .main-footer {
    font-size: 1.8rem;
  }

  .main-footer__picture {
    height: 8rem;
  }

  #sym-logo {
    margin-bottom: 1.2rem;
  }

  #collage {
    margin-top: 1.2rem;
  }

  .main-footer span {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .main-footer svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  #linkedin {
    width: 2.1rem;
    height: 2.1rem;
  }
}

.animate {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s, transform 1.5s;
}

.animate.active {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 500px)
{
  .features-list__item-title {
    font-size: 1.5rem;
  }

  .features-text {
    font-size: 1.3rem;
    line-height: 1.75rem;
  }

  .features-list__icon-box {
    height: 2.5rem;
    width: 2.5rem;
  }

  .features-list__icon-outline {
    height: 1.5rem;
    width: 1.5rem;
  }

  .section-title {
    font-size: 2.5rem;
  }

  .col-title {
    font-size: 2rem;
  }

  .col-text {
    font-size: 1.5rem;
  }

  .accordion-header {
    font-size: 1.5rem;
  }

  .accordion-body {
    font-size: 1.3rem;
  }

  .main-footer {
    font-size: 1.5rem;
  }

  .main-footer__picture {
    height: 6rem;
  }

  #sym-logo {
    margin-bottom: 0.6rem;
  }

  #collage {
    margin-top: 0.6rem;
  }

  .main-footer span {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .main-footer svg {
    width: 2rem;
    height: 2rem;
  }

  #linkedin {
    width: 1.75rem;
    height: 1.75rem;
  }
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

@keyframes wiggle {
  0% {
      transform: rotate(0deg);
  }

  25% {
      transform: rotate(5deg);
  }

  50% {
      transform: rotate(0deg);
  }

  75% {
      transform: rotate(-5deg);
  }

  100% {
      transform: rotate(0deg);
  }
}