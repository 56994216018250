/* Contact Forms */
.form__label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: black;
}

.form__input {
    display: block;
    border-width: 2px;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    line-height: 1.25rem;
    width: 100%;
    padding: 0.625rem;
    background-color: white;
    border-color: #1987c0;
    color: black;
}

.form__input input:focus {
    border-color: #7a4491;
}

.form__checkbox {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 0.25rem;
    border-width: 1rem;
    border-color: #1987c0;
    background-color: white;
    margin-right: 0.5rem;
    cursor: pointer;
}

.form__checkbox-list {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.form__checkbox-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    color: black;
}

.form__error {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #ef4444;
}

.privacy-policy__link:hover {
    color: #7a4491;
}

.opt-in__text {
    font-size: 1.1rem;
}

/* File Upload Input */
.file-upload {
    display: block;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-width: 2px;
    border-radius: 0.5rem;
    cursor: pointer;
    color: black;
    background-color: white;
    border-color: #7a4491;
}

.file-upload::placeholder {
    color: #7a4491;
}

.file-upload::file-selector-button {
    background-color: #7a4491;
    color: white;
    border-width: 2.5px;
    border-color: #7a4491;
    cursor: pointer;
}

.file-upload::file-selector-button:hover {
    background-color: rgba(122, 68, 145, 0.9);
    border-color: rgba(122, 68, 145, 0.9);
}

@media (max-width: 1024px)
{
    .form__label {
        font-size: 1.5rem;
        line-height: 1rem;
    }

    .form__input {
        font-size: 1.5rem;
        line-height: 1rem;
    }

    .form__checkbox {
        width: 1.5rem;
        height: 1.5rem;
    }

    .form__checkbox-item {
        font-size: 1.7rem;
        line-height: 2rem;
    }

    .form__error {
        font-size: 1.5rem;
        line-height: 1.6rem;
    }

    .opt-in__text {
        font-size: 1.7rem;
    }
}

@media (max-width: 500px)
{
    .form__label {
        font-size: 1.25rem;
        line-height: 1rem;
    }

    .form__input {
        font-size: 1.25rem;
        line-height: 1rem;
    }

    .form__checkbox-item {
        font-size: 1.25rem;
        line-height: 1.6rem;
    }

    .form__error {
        font-size: 1.25rem;
        line-height: 1.6rem;
    }

    .opt-in__text {
        font-size: 1.25rem;
    }
}