@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

/* Services List */
.services-list {
    margin-top: 2.5rem;
    max-width: 36rem;
    font-size: 1.2rem;
    line-height: 1.75rem;
    color: black;
}

.services-list__item-container {
    position: relative;
    padding-left: 2.25rem;
}

.services-list__item {
    display: inline;
    font-weight: 600;
    color: #7a4491;
}

.services-list__icon {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    height: 1.25rem;
    width: 1.25rem;
    color: #7a4491;
}

/* Testimonials */
.testimonials__container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: #7a4491;
    border-radius: 2rem;
    border-width: 0.3rem;
    border-color: #1987c0;
    height: 100%;
    width: 100%;
}

.testimonials__profile-picture {
    height: 170px;
    width: 170px;
    object-fit: cover;
    border-radius: 2rem;
    border-width: 0.3rem;
    border-color: #1987c0;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.testimonials__client-name-type {
    font-size: 1.4rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: white;
    text-align: center;
    margin-bottom: 0.5rem;
}

.testimonials__client-details {
    font-size: 1.3rem;
    line-height: 1.75rem;
    font-weight: 400;
    color: white;
    margin-bottom: 1rem;
    text-align: center;
}

.testimonials__quote-container {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-width: 0.55rem;
    border-color: white;
    border-radius: 2rem;
    background-color: white;
}

.testimonials__client-quote {
    font-size: 1.3rem;
    line-height: 1.7rem;
    font-weight: 400;
    color: black;
    padding: 1.2rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    border-radius: 2rem;
    text-align: left;
    height: 20rem;
    overflow: auto;
}

.testimonial__dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem; 
}

.testimonial__dot {
    border-radius: 50%;
    background-color: #686868;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    cursor: pointer;
    padding: 0.5rem;
    color: #c0bfc0;
    font-size: 0.1rem;
    line-height: 0.05rem;
}

.testimonial__dot:hover {
    background-color: #c0bfc0;
    color: #c0bfc0;
}

.testimonials__client-quote::-webkit-scrollbar {
    width: 4px;
  }
  
  .testimonials__client-quote::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #686868;
  }

/* Media Queries */
@media (max-width: 820px) {
    .testimonials__container {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 2rem;
    }
}

@media (max-width: 1024px)
{
    .services-list {
        font-size: 1.7rem;
        max-width: 100%;
    }

    .services-list__item-container {
        padding-left: 3rem;
    }

    .services-list__icon {
        height: 2rem;
        width: 2rem;
    }

    .testimonials__client-name-type {
        font-size: 1.8rem;
    }

    .testimonials__client-details {
        font-size: 1.7rem;
    }

    .testimonials__client-quote {
        font-size: 1.7rem;
        line-height: 2.1rem;
    }
}

@media (max-width: 500px)
{
    .services-list {
        font-size: 1.3rem;
    }

    .services-list__item-container {
        padding-left: 2.5rem;
    }

    .services-list__icon {
        height: 1.5rem;
        width: 1.5rem;
    }

    .testimonials__client-name-type {
        font-size: 1.4rem;
    }

    .testimonials__client-details {
        font-size: 1.3rem;
    }

    .testimonials__client-quote {
        font-size: 1.3rem;
        line-height: 1.7rem;
    }
}