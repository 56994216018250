/* Services Message */
.service-package__container {
    width: 45%;
    border-radius: 2rem;
    border-width: 0.3rem;
    border-color: #7a4491;
    background-color: #7a4491;
    align-items: center;
    justify-content: center;
}

#steadfast-solution {
    background-color: #1987c0;
    border-color: #1987c0;
}

#salvation-solution {
    background-color: #1987c0;
    border-color: #1987c0;
}

.service-package__container:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease;
    box-shadow: 0 0 0.5rem 0.25rem rgba(192, 191, 192, 0.5);
}

.service-package__icon {
    height: 3rem;
    width: 3rem;
    color: black;
    margin-bottom: 1rem;
    border-radius: 0.6rem;
    background-color: white;
    padding: 0.6rem;
}

.service-package__header {
    font-size: 2rem;
    text-align: center;
    line-height: 2.5rem;
    font-weight: 600;
    color: white;
    margin-bottom: 1rem;
}

.service-package__underline {
    width: 100%;
    height: 0.1rem;
    background-color: white;
    margin-bottom: 1rem;
}

.service-package__body {
    height: 14rem;
    font-size: 1.2rem;
    line-height: 1.75rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: left;
    background-color: white;
    border-radius: 2rem;
    border-color: white;
    border-width: 0.55rem;
    padding: 1rem;
    overflow: auto;
}

.service-package__body::-webkit-scrollbar {
    width: 4px;
  }
  
  .service-package__body::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #686868;
  }

/* Media Queries */
@media (max-width: 1024px) {
    .service-package__container {
        width: 100%;
    }

    .service-package__header {
        font-size: 2.4rem;
        line-height: 2.5rem;
    }

    .service-package__icon {
        height: 4rem;
        width: 4rem;
    }

    .service-package__body {
        height: 21rem;
        font-size: 1.8rem;
        line-height: 2.5rem;
    }

    #salvation-solution {
        background-color: #7a4491;
        border-color: #7a4491;
    }

    #spark-the-solution {
        background-color: #1987c0;
        border-color: #1987c0;
    }
}

@media (max-width: 500px)
{
    .service-package__header {
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .service-package__icon {
        height: 3rem;
        width: 3rem;
    }

    .service-package__body {
        height: 14rem;
        font-size: 1.2rem;
        line-height: 1.75rem;
    }
}