/* Team Cards */
#team-cards-section {
    padding-top: 0;
}

.card {
    align-items: center;
    border-radius: 2rem;
    background-color: #7a4491;
    border-color: #7a4491;
    flex-basis: 18rem;
    width: 100%;
    height: 100%;
}

.card:hover {
    transform: scale(1.05);
    transition: transform 0.5s ease;
    box-shadow: 0 0 0.5rem 0.25rem rgba(192, 191, 192, 0.5);
}

.card__image-container {
    width: 320px;
    overflow: hidden;
    border-radius: 2rem;
    border-color: #1987c0;
    border-width: 0.3rem;
}

.card__image {
    height: 300px;
    width: 100%;
}

.card__name {
    font-size: 1.4rem;
    line-height: 1.75rem;
    font-weight: 500;
    letter-spacing: -0.025rem;
    color: white;
    text-align: center;
}

.card__position {
    color: #c0bfc0;
    text-align: center;
    font-size: 1.2rem;
}

@media (max-width: 1024px) {
    .card {
        width: 120rem;
        height: 33.75rem;
    }

    .card__image-container {
        height: 380px;
        width: 400px;
    }

    .card__image {
        height: 380px;
        width: 400px;
    }

    .card__name {
        font-size: 1.8rem;
        line-height: 1.25rem;
        margin-bottom: 0.5rem;
    }

    .card__position {
        font-size: 1.6rem;
    }
}

@media (max-width: 500px)
{
    .card {
        flex-basis: 18rem;
        width: 100%;
        height: 100%;
    }

    .card__image-container {
        height: 320px;
        width: 327px;
    }

    .card__image {
        height: 320px;
        width: 327px;
    }
}