/* Navbar */
.navbar {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #1987c0;
    color: white;
    z-index: 100;
}

.navbar__logo {
    display: none;
}

.navbar__burger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #7a4491;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
}

.navbar__burger-icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
}

.navbar__burger:hover {
    background-color: #7a4491;
}

.navbar__menu {
    margin-left: 0;
}

.navbar__menu-item {
    top: 50%;
    transform: translateY(10%);
}

.navbar__rocks-logo {
    height: 3rem;
    width: 3rem;
    background-color: white;
    padding: 0.5rem;
    border-radius: 50%;
}

.navbar__menu ul {
    display: flex;
    padding: 0;
}

.navbar__menu li {
    list-style: none;
    font-size: 1.4rem;
    margin: 0 1rem;
}

.navbar__menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 0 0.5rem;
    top: 90%;
}

.navbar__menu li a:hover {
    background-color: #7a4491;
    border-radius: 4rem;
}

.navbar__dark-mode {
    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
    background-color: #7a4491;
    border-radius: 50%;
    padding: 0.5rem;
    height: 40px;
    width: 40px;
}

.navbar__dark-mode-icon {
    width: 1.5rem;
    height: 1.5rem;
}

/* Media Queries */
@media screen and (max-width: 1024px) {
    .navbar {
        height: 140px;
    }

    .navbar__logo {
        display: block;
        position: absolute;
        left: 1.5rem;
        width: 250px;
    }

    .navbar__burger {
        display: block;
        height: 70px;
        width: 70px;
        padding: 0.9rem;
    }

    .navbar__burger-icon {
        width: 2.5rem;
        height: 2.5rem;
    }

    .navbar__menu ul {
        position: absolute;
        top: 140px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 60px);
        background-color: #1987c0;
        display: none;
    }

    .navbar__menu li {
        text-align: center;
        font-size: 2.5rem;
        margin: 0;
    }

    .navbar__menu li a {
        color: white;
        width: 100%;
        padding: 1.5rem 0;
    }

    .navbar__menu li a:hover {
        background-color: #7a4491;
        border-radius: 4rem;
    }

    .navbar__menu.expanded ul {
        display: block;
    }

    .navbar__dark-mode {
        height: 70px;
        width: 70px;
        padding: 0.9rem;
        right: 110px;
    }

    .navbar__dark-mode-icon {
        width: 2.5rem;
        height: 2.5rem;
    }
}

@media (max-width: 500px)
{
    .navbar {
        height: 100px;
    }

    .navbar__logo {
        width: 150px;
    }

    .navbar__burger {
        height: 40px;
        width: 40px;
        padding: 0.5rem;
    }

    .navbar__burger-icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .navbar__menu ul {
        top: 100px;
    }

    .navbar__menu li {
        font-size: 1.5rem;
    }

    .navbar__dark-mode {
        height: 40px;
        width: 40px;
        padding: 0.5rem;
        right: 80px;
    }

    .navbar__dark-mode-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
}