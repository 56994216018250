p {
    margin-bottom: 1rem;
    font-size: 1.3rem;
}

.list {
    list-style: disc;
    margin-left: 3rem;
    margin-bottom: 1rem;
    font-size: 1.3rem;
}

.list a {
    text-decoration: underline;
}

.list a:hover {
    color: #1987c0;
}

@media (max-width: 1024px) {
    p {
        font-size: 2rem;
    }

    .list {
        font-size: 2rem;
    }
}

@media (max-width: 500px) {
    p {
        font-size: 1.5rem;
    }

    .list {
        font-size: 1.5rem;
    }
}