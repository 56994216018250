/* Fade Modal */
.fade-modal__name {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    color: #7a4491;
}

.fade-modal__description {
    text-align: left;
    font-size: 1.5rem;
    line-height: 2.4rem;
    padding-top: 1rem;
}

.fade-modal__underline {
    border-bottom-color: #1987c0;
    border-bottom-width: 3px;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

/* Media Queries */
@media (max-width: 768px) {
    .fade-modal__underline {
        width: 60%;
    }
}

@media (max-width: 1024px)
{
    .fade-modal__name {
        font-size: 2.5rem;
    }

    .fade-modal__description {
        font-size: 2rem;
        line-height: 2.7rem;
    }
}

@media (max-width: 500px)
{
    .fade-modal__name {
        font-size: 1.5rem;
    }

    .fade-modal__description {
        font-size: 1.2rem;
        line-height: 2rem;
    }
}